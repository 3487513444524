
import { Vue, Component } from 'vue-property-decorator'
import nps from '@/services/nps'
import CharterUPLogo from '@/icons/CharterUPLogo.vue'
import { NPSTypeId } from '@/utils/enum'

@Component({
  components: { CharterUPLogo },
})
export default class NPSSurvey extends Vue {
  hash = ''
  comments = ''
  rating = null
  errorMessage = false
  loading = false

  mounted(): void {
    this.hash = this.$route.params.hash
    this.calculateRating()
  }

  calculateRating(): void {
    const rating = Number(this.$route.query.npsScore)
    this.rating = !isNaN(rating) && rating >= 0 && rating <= 10 ? rating : null
  }

  async submit() {
    this.loading = true
    const payload = {
      rating: this.rating,
      comments: this.comments,
      npsTypeId: NPSTypeId.PostTrip,
    }
    try {
      await nps.addByHash(this.hash, payload)
      this.$ga4Event('take_nps_survey', {
        page: 'Post-trip Survey',
      })
      this.$router.push({ name: 'nps-thank-you' })
    } catch (e) {
      console.error(e)
    }
    this.loading = false
  }
}
