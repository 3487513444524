var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-container',{attrs:{"text-center":""}},[_c('CharterUPLogo',{staticClass:"margin-t-10",attrs:{"width":"150"}}),_c('div',{staticClass:"margin-b-16"},[_c('div',{staticClass:"margin-t-10 margin-b-4"},[_c('p',[_vm._v(" "+_vm._s(_vm.$t('npsSurvey.HEADER'))+" ")]),_c('v-divider',{staticClass:"margin-t-4 margin-b-8"}),_c('p',{staticClass:"font-22"},[_vm._v(" "+_vm._s(_vm.$t('npsSurvey.RATING_LABEL'))+" ")])],1),_c('div',{staticClass:"d-flex flex-wrap justify-center margin-b-15 margin-x-auto max-w-600"},_vm._l((11),function(_,val){return _c('div',{key:val,staticClass:"d-flex flex-grow-0 border-solid border-1 margin-a-1 rounded elevation-2 align-center justify-center h-34 w-40 background-white font-medium font-14 cursor-pointer border-primary hover:background-border-gray",class:{
          'text-white background-primary': val === _vm.rating,
        },on:{"click":function($event){_vm.rating = val}}},[_vm._v(" "+_vm._s(val)+" ")])}),0),_c('div',[_c('p',{staticClass:"font-22"},[_vm._v(" "+_vm._s(_vm.$t('npsSurvey.TEXTAREA_LABEL'))+" ")]),_c('v-textarea',{staticClass:"margin-t-6 margin-b-8 w-600 margin-x-auto",attrs:{"autocomplete":"off","counter":1000,"rules":[
          (v) =>
            (v || '').length <= 1000 ||
            'Description must be 1000 characters or less',
        ],"outlined":"","height":"150","no-resize":""},on:{"update:error":(e) => (_vm.errorMessage = e)},model:{value:(_vm.comments),callback:function ($$v) {_vm.comments=$$v},expression:"comments"}}),_c('v-btn',{attrs:{"color":"primary","disabled":_vm.rating == null || _vm.errorMessage,"loading":_vm.loading},on:{"click":_vm.submit}},[_vm._v(" "+_vm._s(_vm.$t('npsSurvey.BUTTON_TEXT'))+" ")])],1)]),_c('v-divider',{staticClass:"margin-b-2"}),_c('v-footer',{staticClass:"font-12 background-white justify-center"},[_vm._v(" "+_vm._s(_vm.$t('common.COPYRIGHT'))+" ")])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }